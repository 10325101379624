import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';

interface ApiResponse<T> {
    data: T;
    status: boolean;
    message?: string;
    errors?: Record<string, string[]>;
    error?: string;
}

const instance: AxiosInstance = axios.create({
    baseURL: '/',
});

instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

const getCsrfToken = (): string | null => {
    const meta = document.querySelector('meta[name="csrf-token"]');
    return meta ? meta.getAttribute('content') : null;
};

const getAuthConfig = (): AxiosRequestConfig => {
    const csrfToken = getCsrfToken();
    return csrfToken
        ? {
              headers: {
                  Authorization: `Bearer ${csrfToken}`,
              },
          }
        : {};
};

const get = async <T>(url: string, requireAuth: boolean = false): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<ApiResponse<T>> = requireAuth
            ? await instance.get(url, getAuthConfig())
            : await instance.get(url);
        return response.data;
    } catch (error) {
        return handleError(error as AxiosError);
    }
};

const post = async <T>(
    url: string,
    payload: any,
    requireAuth: boolean = false
): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<ApiResponse<T>> = requireAuth
            ? await instance.post(url, payload, getAuthConfig())
            : await instance.post(url, payload);
        return response.data;
    } catch (error) {
        return handleError(error as AxiosError);
    }
};

const patch = async <T>(
    url: string,
    payload?: any,
    requireAuth: boolean = false
): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<ApiResponse<T>> = requireAuth
            ? await instance.patch(url, payload, getAuthConfig())
            : await instance.patch(url, payload);
        return response.data;
    } catch (error) {
        return handleError(error as AxiosError);
    }
};

const put = async <T>(
    url: string,
    payload: any = {},
    requireAuth: boolean = false
): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<ApiResponse<T>> = requireAuth
            ? await instance.put(url, payload, getAuthConfig())
            : await instance.put(url, payload);
        return response.data;
    } catch (error) {
        return handleError(error as AxiosError);
    }
};

const del = async <T>(
    url: string,
    payload: any = {},
    requireAuth: boolean = true
): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<ApiResponse<T>> = requireAuth
            ? await instance.delete(url, { ...getAuthConfig(), data: payload })
            : await instance.delete(url, { data: payload });
        return response.data;
    } catch (error) {
        return handleError(error as AxiosError);
    }
};

const handleError = (error: AxiosError): ApiResponse<null> => {
    const errorData = error.response?.data as any;
    return {
        data: null,
        status: false,
        message: errorData?.message || 'An error occurred',
        errors: errorData?.errors,
    };
};

export { instance as api, del, get, patch, post, put };